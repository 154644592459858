<template>
  <page-section >
    <v-sheet
        class="d-flex"
        style="position: relative"
        :height="height"
        color="transparent"
    >
      <div
          style="
            position:absolute; z-index: 2; right: 650px;;left: 0; top: 0;
            height: 250px;
  background: linear-gradient(to top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
"
      ></div>
      <v-sheet  color="transparent" ref="content"  :height="height"  class="overflow-hidden"  >

        <v-sheet
            color="transparent"
            class="d-flex align-center fill-height"
             v-for="(icon, item ) in casesSelect" :key="item">
          <v-sheet color="transparent"   v-intersect="($event) => intersector($event , item)">
            <v-icon large :color="wsATTENTION">
              mdi-{{ icon }}
            </v-icon>
            <h3 style="font-size: 19px" :style="`color : ${wsDARKER}`" class="mt-7">
              {{ $t(`homepage.sections.possibilities.${item}.title`) }}
            </h3>
            <h4 class="font-weight-regular mt-2 mb-10">
              {{ $t(`homepage.sections.possibilities.${item}.text`) }}
            </h4>
          </v-sheet>
        </v-sheet>
      </v-sheet>
      <v-sheet color="transparent" :height="height" class="pt-4 pb-16"  min-width="620" width="620">
        <v-sheet class="wsRoundedHalf fill-height d-flex align-center justify-center"  :color="wsBACKGROUND">
          <code> {{ currentSlide }}</code>
        </v-sheet>
      </v-sheet>

      <div
          style="
           background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            position:absolute; z-index: 2; right: 650px;left: 0; bottom: 0;
            height: 250px;"
      ></div>


    </v-sheet>


  </page-section>
</template>

<script>
export default {
  name: "homeSectionPossibilitiesSlider",
  data() {
    return {
      currentSlide : '',
      height : 680,
      intersectedWindowBottom : false,
      scrolledInitialPosition : 0,
      scrollBuffer : 0,
      disableScroll : false,
      casesSelect : {
        crm : 'bank',
        payments : 'wallet',
        experts : 'account-multiple',
      }
    }
  },
  methods : {
    intersector(entries , value) {

      if (entries[0]) {
        if (entries[0].isIntersecting && entries[0].boundingClientRect.y > 0) {
          this.intersectedWindowBottom = true
          this.scrolledInitialPosition = 0
          this.currentSlide = value
        }
      }
    },
    scrollLogic($event) {
      $event.preventDefault()



      const content = this.$refs.content.$el
      const container = this.$refs.content.$el.getBoundingClientRect()
      const clientHeight = document.documentElement.clientHeight
      const scrollTop = document.documentElement.scrollTop
      const scrollLeft = document.documentElement.scrollTop
      const scrollBottom = scrollTop + clientHeight
      const bottomPosition = container.top + scrollTop + container.height

   //   const scrollNext = scrollTop > this.scrollBuffer

      if ( !this.disableScroll) {
        this.scrollBuffer = bottomPosition
      }



      let distanceScrolled = scrollBottom - bottomPosition
      const scrollHeightMax = content.scrollHeight - container.height

        if ( distanceScrolled > 0 && distanceScrolled < content.scrollHeight  && distanceScrolled  < content.scrollHeight && content.scrollTop < scrollHeightMax  ) {
          content.scrollTop += distanceScrolled
          window.scroll(scrollLeft, this.scrollBuffer - clientHeight );
          this.disableScroll = true
        } else if ( distanceScrolled < 0 && content.scrollTop > 0) {
          content.scrollTop += distanceScrolled
          window.scroll(scrollLeft, this.scrollBuffer - clientHeight );
          this.disableScroll = true
        } else {
          this.disableScroll = false
        }










    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollLogic)

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollLogic)
  }
}
</script>

<style scoped>

</style>